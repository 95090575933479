/* .te-theme .btn.btn-primary, */
.te-theme .tb_error_btn_primary,
.te-theme .chooseBanner .bnr-action .btn-apply,
.te-theme .radio.radio-outline.radio-primary>input:checked~span:after,
.te-theme .upgrade_notifiction .btn,
/* .te-theme .upgPlan, */
.te-theme .sidebar_action .publish_wt,
.te-theme .influential_contributors_custom_modal .btn-primary,
.te-theme .behind-blur .btn-primary,
.te-theme .card-footer .btn-primary,
.te-theme .upgrade-cancel-link .btn-primary,
.te-theme .card-toolbar .btn-primary,
.te-theme .emebed_action .btn-primary,
.te-theme .connectedAccounts_list .btn-primary,
.te-theme .price_btn .btn-primary,
.te-theme .modal-footer .btn-primary,
.te-theme .input-group .btn-primary,
.te-theme .drp-buttons .btn-primary,
.te-theme .moderation_modal_inner .btn-primary,
.te-theme .reqnetwork .btn-primary,
.te-theme .inappFoot .btn-primary,
.te-theme .create_feed_hideshow .btn-primary,
.te-theme .publish_steps .btn-primary,
.te-theme .form-actions .btn-primary,
.te-theme .btn-primary.rounded-circle,
.te-theme .ra-actions .btn-primary,
.te-theme .add-feed-footer .btn-primary,
.te-theme .portlet-body .btn-primary,
.te-theme .addTagsPost.btn-primary,
.te-theme .modal-dialog .btn-primary,
.te-theme .noPostMsgBlock .btn-primary,
.te-theme .small_container .btn-primary,
.te-theme .payment-failed .btn-primary,
.te-theme .upgrade-confirm-area .btn-primary,
.te-theme .buttonUpgred .btn-primary,
.te-theme .selectSource-chatbox .btn-primary,
.te-theme .wall_action_btn .btn-primary {
  background-color: #4179ff;
  border-color: #4179ff;
}

.te-theme .filer-custom-desgin .active .tb_filter_round_edged_button_text,
.te-theme .filer-custom-desgin .active .tb_filter_round_edged_button_text:hover {
  background-color: #ffe7e9;
  color: #4179ff;
}
.te-theme .publish-tab .tab-content .display_modal .postLoader {
  min-height: inherit;
}

.te-theme .modal-dialog textarea {
  resize: none;
}

.te-theme .dropdown-toggle.show {
  border-color: transparent;
}

.te-theme .btn-chat-now:active,
.te-theme .btn-book-call:active,
.te-theme .create_feed_hideshow .btn-secondary,
.te-theme .card-toolbar .btn-secondary:active,
.te-theme .modal-footer .btn-secondary:active,
.te-theme .chooseBanner .bnr-action .btn-edit:active,
.te-theme .user_details_section .btn-connect:active,
.te-theme .hideforBSearch .btn-secondary:active,
.te-theme .small_container .btn-secondary:active,
.te-theme .payment-failed .btn-secondary:active {
  border-color: #4179ff;
  color: #4179ff;
}

.te-theme .chooseBanner .bnr-action .btn-edit:active {
  background-color: #fff;
}

.rc-slider-handle:active {
  border-color: #4179ff;
  box-shadow: 0 0 5px #4179ff;
}

#initPublishPopupId:active .sidebar_btn_txt,
.te-theme .influential_contributors_custom_modal .btn-primary:active,
/* .te-theme .dropdown .btn.btn-light i, */
.te-theme .chooseBanner .bnr-action .btn-edit:active:hover {
  color: #fff;
}

.te-theme .filters_tabs .reset_btn:active,
.te-theme .btn.btn-outline-primary {
  color: #4179ff;
  border-color: #4179ff;
}

.te-theme #analytics_main .cutsom-tab-viewall .nav-link.active {
  box-shadow: 0px -3px 0 0px #4179ff inset;
}

.te-theme .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
.te-theme .btn.btn-primary:focus:not(.btn-text),
.te-theme .btn.btn-primary.focus:not(.btn-text),
.te-theme .select-layout :not(.btn-check)+.btn:active,
.te-theme .btn.btn-outline-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
.te-theme .btn.btn-outline-primary:focus:not(.btn-text),
.te-theme .btn.btn-outline-primary.focus:not(.btn-text),
.te-theme .tb_onsite_btn_primary:focus,
.te-theme .tb_onsite_btn_primary:hover,
.te-theme .tb_error_btn_primary:focus,
.te-theme .tb_error_btn_primary:hover {
  background-color: #2d65eb;
  border-color: #2d65eb;
}

.te-theme .brand {
  padding: 0 18px;
}

.te-theme .loginBlock a,
.te-theme .loginBlock .btnSignUp,
.te-theme .calendlyAclick .btn-link,
.te-theme .themesNowThumbs ul li.active a span,
.te-theme .themesNowThumbs ul li:hover a span,
.te-theme .tb_filter_rectangular_button.tb-network-rectangular-ico-users,
.te-theme .performing-widget .view-all:hover,
.te-theme .wall_action_btn li .btn.active,
.te-theme .wall_action_btn li .btn:first-child:active,
.te-theme .upgrade-text,
.te-theme .tb_error_highlight,
.te-theme .filer-custom-desgin .tb_filter_round_edged_button_text:hover {
  color: #4179ff;
  /*Normal*/
}

.te-theme .embed_code_wrap .nav-link.active svg path[fill],
.te-theme .embed_code_wrap .nav-link:hover svg path[fill] {
  fill: #4179ff;
}

.te-theme .camp_post_wrap label.active {
  outline: rgb(65, 121, 255) solid 2px !important;
}

.te-theme .chooseBanner .bnr-action .btn-apply:active,
.te-theme #initPublishPopupId .sidebar_btn_txt:active {
  color: #fff;
}

.te-theme .upgrade-plans .upgrade-plan,
.te-theme .loginBlock .form-group .btn-primary,
.te-theme .aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-link .menu-bullet.menu-bullet-dot>span,
.te-theme .network-filter .filer-custom-desgin>div.active {
  background-color: #4179ff;
}

.te-theme .wall-switcher:nth-child(1n) .switcher-thumbnail {
  background-color: #037bfe17;
}

.te-theme .calendlyAclick .btn-link:hover,
.te-theme .loginBlock a.pull-right:hover,
.te-theme .noPostMsgBlock a.text-primary:hover

/*,.te-theme a.text-primary:hover,
.te-theme .btn-check:checked + .btn,
.te-theme :not(.btn-check) + .btn:active,
.te-theme .btn:first-child:active,
.te-theme .btn.active,
.te-theme .btn.show*/
  {
  color: #2d65eb !important;
  /*Hover*/
}

.te-theme a.text-hover-primary:hover,
.te-theme .text-hover-primary:hover,
.te-theme .uppy-Dashboard-AddFiles-title button,
.te-theme .text-primary,
.te-theme a.text-primary:focus,
.te-theme .bootstrap-datetimepicker-widget .datepicker .datepicker-days table tbody tr>td.today {
  color: #4179ff !important;
  /*Normal!mportant*/
}

.te-theme .daterangepicker .ranges li.active:before,
/* .te-theme .btn-check:checked+.btn,
.te-theme :not(.btn-check)+.btn:active,
.te-theme .btn:first-child:active,
.te-theme .btn.active,
.te-theme .btn.show, */
.te-theme .border-bottom span.active,
.te-theme .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging,
.te-theme .loginBlock .form-group .btn-primary,
.te-theme .add-feed-modal .themesNowThumbs ul li.active a,
.te-theme .layout_box .layout_style.active,
.te-theme .layout_box .layout_style:hover,
.te-theme .img_uploader_icon:active {
  border-color: #4179ff;
}

.te-theme .bootstrap-datetimepicker-widget .datepicker .datepicker-days table tbody tr>td.today:before {
  border-bottom-color: #4179ff;
  border-top-color: #4179ff;
}

.te-theme .ex-postcenter .nav-link.active {
  border-bottom-color: #4179ff;
}

.te-theme .daterangepicker td.in-range,
.te-theme .daterangepicker .ranges li:hover,
.te-theme .daterangepicker td.available:hover,
.te-theme .daterangepicker th.available:hover,
.te-theme .apiJsonXmlSec .color-demo a:before {
  background-color: #e1f0ff;
}

.te-theme .menu-nav>.menu-item>.menu-link:before,
.te-theme .nav.nav-tabs.nav-tabs-line .nav-link:after,
.te-theme .daterangepicker td.active.available,
.te-theme .daterangepicker td.active:hover,
body.te-theme .swal2-styled.swal2-confirm,
.te-theme .tb-network-rectangular-ico-users .tb_filter_rectangular_icon:after,
.te-theme .tb_onsite_btn_primary {
  background-color: #4179ff;
}

.te-theme .new-tag {
  background-color: #037bfe;
  color: #fff;
}

.te-theme .svg-icon.svg-icon-primary svg path {
  /* fill: #4179ff !important; */
}

.te-theme .nav.nav-tabs.nav-tabs-line .nav-link.active,
.te-theme .nav.nav-tabs.nav-tabs-line .nav-link:hover:not(.disabled),
.te-theme .nav.nav-tabs.nav-tabs-line .show>.nav-link {
  /* border-bottom: 2px solid #4179ff; */
  border-color: #4179ff;
}

.te-theme .rc-slider-disabled .rc-slider-track,
.te-theme .rc-slider-track,
.te-theme .rc-slider-tracks,
.te-theme div.dataTables_wrapper div.dataTables_paginate>span>.paginate_button.current,
body.te-theme .swal2-styled.swal2-confirm {
  background-color: #4179ff !important;
}

.te-theme ul.networks li .add_feed_modal img {
  width: 50px;
  border-radius: 6px;
}

.te-theme ul.networks li .add_feed_modal img[alt="Etsy"] {
  box-shadow: 0 0 0 6px #f75e01 inset, 0px 4px 0 6px #f75e01 inset,
    0px -4px 0 6px #f75e01 inset;
}

.te-theme .symbol.symbol-30>img {
  border-radius: 50%;
}

.te-theme .selectpicker__menu .selectpicker__option.selectpicker__option--is-selected,
.te-theme .selectpicker__menu .selectpicker__option.selectpicker__option--is-selected:active,
.te-theme .selectpicker__menu .selectpicker__option.selectpicker__option--is-selected:focus,
.te-theme li.ng-scope.active a {
  background-color: #4179ff;
}

.te-theme .selectpicker__menu .selectpicker__option--is-focused,
.te-theme .selectpicker__menu .selectpicker__option--is-focused:focus,
.te-theme .selectpicker__menu .selectpicker__option--is-focused:active,
.te-theme .selectpicker__menu .selectpicker__option:active {
  background-color: #e1f0ff;
}

/*  */
.te-theme .postLoader {
  position: relative;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 72px;
  margin: 0 auto;
  background-color: transparent;
}

.te-theme .postLoader svg {
  width: 180px;
  height: 32px;
}

.te-theme .loading {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
}

.te-theme .postLoader>div {
  width: 30px;
  height: 30px;
  border: 2px dotted transparent;
  border-radius: 50%;
  padding: 10px;
  box-shadow: inset 0 1px 14px rgba(0, 0, 0, 0.3);
  box-sizing: unset;
}

.te-theme .postLoader>div {
  -webkit-animation: 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate flip-vertical-right;
  animation: 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate flip-vertical-right;
}

@-webkit-keyframes flip-vertical-right {
  0% {
    -webkit-transform: rotateY(0) scale(0.9);
    transform: rotateY(0) scale(0.9);
  }

  100% {
    -webkit-transform: rotateY(360deg) scale(1);
    transform: rotateY(360deg) scale(1);
  }
}

@keyframes flip-vertical-right {
  0% {
    -webkit-transform: rotateY(0) scale(0.9);
    transform: rotateY(0) scale(0.9);
  }

  100% {
    -webkit-transform: rotateY(360deg) scale(1);
    transform: rotateY(360deg) scale(1);
  }
}

/*  */

@media (min-width: 992px) {
  .te-theme .more_cards:hover .more_svg_icon {
    background-color: #e1f0ff;
  }
}
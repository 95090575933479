.review-hub {
    border-radius: 6px;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 0px 0px 8px 0px #0000001F;
    height: calc(100vh - 130px);
}

/* Action-START */
.review-action {
    width: 25%;
    border-radius: 6px 0 0 6px;
    background-color: #fff;
}

.ra-presha {
    width: 75%;
}

.ra-title {
    padding: 10px 15px;
    border-bottom: 1px solid #DBE1E5;
    ;
}

.ra-title p {
    font-weight: 500;
    color: #2b2b2b;
}

.ra-tab {
    padding: 15px;
    padding-bottom: 0;
    background-color: #f7f7fb;
}

.ra-tab-wrap {
    border-radius: 6px;
    border: 1px solid #DBE1E5;
    background: #fff;
    padding: 3px;
}

.ra-tab span {
    color: #878787;
    font-size: 13px;
    font-weight: 500;
    width: 50%;
    text-align: center;
    display: inline-block;
    padding: 7px 10px;
}

.ra-tab span em {
    font-style: normal;
}

.ra-tab span:hover {
    cursor: pointer;
}

.ra-tab span.active {
    position: relative;
    background: #19232E;
    color: #fff;
    border-radius: 4px;
}

.ra-tab span.active:hover {
    cursor: inherit;
}

.ra-tab span svg {
    max-height: 20px;
    margin-right: 5px;
    vertical-align: middle;
}

.ra-tab span.active svg rect {
    fill: transparent;
}

.ra-tab span.active svg path {
    fill: white;
}

.ra-formwrap {
    background: #F7F7FB;
    padding: 15px;
}

.ra-actions {
    padding: 15px;
}

.ra-formwrap .form-control {
    border: 0;
    background: transparent;
    padding: 0;
    margin-bottom: 10px;
}

.ra-formwrap .form-control label {
    font-size: 13px;
    color: #626B76;
    font-weight: 500;
    display: block;
    text-transform: capitalize;
}

.ra-formwrap .form-control input[type="text"]::-webkit-input-placeholder,
.ra-formwrap .form-control textarea::-webkit-input-placeholder {
    color: #96A2B1;
    opacity: 0.8;
}

.ra-formwrap .form-control input[type="text"]::-moz-placeholder,
.ra-formwrap .form-control textarea::-moz-placeholder {
    color: #96A2B1;
    opacity: 0.8;
}

.ra-formwrap .form-control input[type="text"]:-ms-input-placeholder,
.ra-formwrap .form-control textarea:-ms-input-placeholder {
    color: #96A2B1;
    opacity: 0.8;
}

.ra-formwrap .form-control input[type="text"]:-moz-placeholder,
.ra-formwrap .form-control textarea:-moz-placeholder {
    color: #96A2B1;
    opacity: 0.8;
}

.ra-formwrap .form-control input[type="text"],
.ra-formwrap .form-control textarea {
    font-size: 13px;
    font-weight: 500;
    background-color: #fff;
    padding: 10px 15px;
    border: 0;
    color: #333;
    width: 100%;
    border-radius: 2px;
}

.ra-formwrap .form-control textarea {
    resize: none;
    border-radius: 4px;
}

.ra-formwrap .checkbox.checkbox-outline.checkbox-primary>input:checked~span,
.ra-formwrap .checkbox.checkbox-outline.checkbox-primary>input:checked~span:after {
    border-color: transparent;
}

.ra-formwrap .switch input:empty~span {
    margin: 0;
    height: 21px;
}

.ra-formwrap .switch input:empty~span:after {
    width: 14px;
    height: 14px;
    left: 0px;
}

.ra-formwrap .form-control input[type="text"]:focus,
.ra-formwrap .form-control textarea:focus {
    box-shadow: 0 0 10px #f1f1f1;
    outline: none;
}

.qr-code-section .btn-submit,
.qr-code-section .btn-submit:hover {
    box-shadow: 0px 1.05px 0px 0px #0000000D;
    background-color: #1A232F;
    color: #fff;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
}

.qr-code-section .btn-submit:hover {
    opacity: 0.9;
}

/* Share-START */
.review-share {
    width: 50%;
    padding: 15px;
    padding-right: 40px;
    padding-top: 80px;
    border-radius: 0 6px 6px 0;
    background-color: #F7F7FB;
}

.hashtag_social_icons i {
    color: #626B76;
    font-size: 20px;
}

.image-upload-control label {
    font-size: 13px;
    font-weight: 500;
    color: #626B76;
}

.image-upload-control .fc-uploader,
.image-upload-control .fc-uploaded {
    background-color: #fff;
    border: 1px dashed #626B76;
    border-radius: 4px;
    padding: 5px 15px;
    min-height: 46px;
    max-height: 46px;
}

.image-upload-control .fc-uploaded {
    padding: 7px 15px;
    display: flex;
    align-items: center;
}

.image-upload-control .fc-uploader:hover {
    cursor: pointer;
}

.image-upload-control .fc-uploaded img {
    max-width: 100%;
    max-height: 25px;
    margin: 0 auto;
}

.image-upload-control .fc-uploaded-inn {
    max-width: 100%;
    margin: 0 auto;
    position: relative;
    text-align: center;
    background: #f9f9f9;
    border-radius: 4px;
    padding: 5px;
}

.image-upload-control .fc-uploaded .close {
    position: absolute;
    right: -5px;
    top: -5px;
    background: #fff;
    width: 14px;
    height: 14px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    padding: 1px;
    opacity: 0.75;
    line-height: 12px;
    transition: all 0.3s ease-in-out;
}

.image-upload-control .fc-uploaded .close i {
    font-size: 12px;
}

.image-upload-control .fc-uploaded .close:hover {
    opacity: 1;
    cursor: pointer;
}

.image-upload-control .fc-uploader span {
    font-size: 12px;
    font-weight: 500;
    color: #2b2b2b;
    display: inline-block;
    margin-left: 5px;
}

.ra-actions span i {
    margin-right: 8px;
    font-size: 14px;
}

div.moderate-public .checkbox {
    display: flex;
    align-items: flex-start;
}

.checkbox>span {
    border-color: transparent;
}

.moderate-public #moderation-toggle {
    margin-right: 5px;
    z-index: 99;
    opacity: 1;
    width: 16px;
    height: 16px;
    top: 2px;
}

.qr-code-section {
    background: #fff;
    padding: 10px;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 0 2px #DBE1E5;
}

.qr-rightsec {
    margin-left: 15px;
}

.qr-rightsec p {
    color: #2b2b2b;
    padding-right: 10px;
}

.rs-secone .rss-count {
    width: 36px;
}

.rs-secone .rss-count span {
    width: 24px;
    height: 24px;
    display: block;
    background: #dddddd;
    border-radius: 50%;
    text-align: center;
    line-height: 24px;
    margin-right: 10px;
    color: #2b2b2b;
    font-weight: 500;
}

.ra-tab-wrap>span:nth-child(2) svg {
    margin-top: -3px;
}

.rss-content #seturlVal {
    padding-right: calc(1.5em + 1.3rem + 32px);
}

.ra-scroll,
.ra-presha {
    overflow-y: auto;
}

div.t_star_ico {
    padding: 0px 8px 4px;
}

/* Onsite new modal section*/
.snapUpContainer {
    min-height: calc(100vh - 175px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 50%;
}

.onsite_preview_inner {
    position: relative;
    transform: scale(0.8);
    height: 100%;
}

.t_modal {
    transform: scale(0.81);
    width: 124%;
    height: 124%;
    margin-left: -12%;
    margin-right: -12%;
    margin-top: -12%;
    margin-bottom: -12%;
    z-index: 1;
    overflow: hidden;
}

#subtree-container .t_modal {
    z-index: 9999;
}

.tb_onsite_upload_btn_wrap {
    padding: 10px
}

.tb_onsite_upload_btn,
.tb_onsite_upload_btn_wrap {
    align-items: center;
    display: flex;
    justify-content: center
}

.tb_onsite_upload_btn {
    flex-direction: column;
    max-width: 350px
}

.tb_onsite_btn {
    background-color: #000;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    font-weight: 500;
    padding: 6px
}

.tb_onsite_btn,
.tb_onsite_qr_btn_wrap {
    align-items: stretch;
    display: flex;
    justify-content: center
}

.tb_onsite_qr_code {
    align-items: center;
    background: #fff;
    display: flex;
    justify-content: center;
    object-fit: cover;
    padding: 5px
}

.tb_onsite_upload_btn .btn-scan canvas {
    height: 100%;
    object-fit: cover;
    width: 100%
}

.tb_onsite_btn_txt {
    align-items: center;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    padding: 0 10px
}

.t_modal_container {
    min-height: calc(100% - 40px);
    max-width: 700px;
    width: 100%;
    margin: 20px auto;
    padding: 0 15px;
    align-items: center;
    display: flex;
}

.t_modal {
    --ts-star-color: #fbbc04;
    --ts-progress-color: #545454;
    --ts-progress-color-rgb: 0, 0, 0;
}

.t_modal.t_brand_ {
    --ts-star-color: #4E69ED;
    --ts-progress-color: #4E69ED;
    --ts-progress-color-rgb: 78, 105, 237;
}

.t_modal_container {
    min-height: calc(100% - 40px);
    max-width: 600px;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.t_modal_content {
    background-clip: padding-box;
    background-color: #fff;
    color: #000;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    position: relative;
    width: 100%;
    outline: 0px;
    padding: 20px;
    min-height: 400px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, .1);
}

.t_m_logo_w {
    display: block;
    text-align: center;
    padding: 10px;
    margin-bottom: 10px;
    padding-top: 0px;
}

.t_m_logo {
    max-width: 200px;
    height: auto;
    max-height: 45px;
    object-fit: contain;
}

.t_m_title {
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 0;
    font-weight: 600;
    text-align: center;
    line-height: normal;
}

.t_m_subtitle {
    font-size: 14px;
    margin-bottom: 20px;
    margin-top: 0;
    text-align: center;
    line-height: normal;
    color: #545454;
}

.t_label_ {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 8px;
    color: #2b2b2b;
}

.t_label_center {
    text-align: center;
}

.t_t_danger {
    display: inline;
    color: red;
    font-size: 14px;
}

.t_from_input,
.t_from_textarea {
    border: 1px solid #BABFC3;
    border-radius: 3px;
    box-shadow: none;
    font-family: inherit;
    font-size: 14px;
    outline: none;
    padding: 10px;
    width: 100%;
    color: #2b2b2b;
}

.t_from_textarea {
    min-height: 120px;
    resize: vertical;
}

.t_from_input:focus,
.t_from_textarea:focus {
    border-color: #2b2b2b;
}

.t_f_group {
    margin-bottom: 15px;
}

.t_from_check {
    appearance: none;
    background-color: #fff;
    background-image: var(--bs-form-check-bg-image);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    border: 1.5px solid #2b2b2b;
    flex-shrink: 0;
    margin-right: 6px;
    margin-top: 0;
    border-radius: 2px;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    width: 1.3em !important;
    height: 1.3em !important;
}

.t_m_upload {
    height: 76px;
    width: 76px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    color: #BABFC3;
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    text-align: center;
    border: 1px solid #e6e6eb;
    padding: 5px;
}

.t_m_upload.t_m_dd {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 0;
}

.t_m_up_wrp {
    position: relative;
    width: 100%;
    width: 100%;
}

.t_m_upload .uppy-u-reset {
    padding: 20px 15px;
    background-color: #F7F7FB;
    display: block;
}

.t_m_upload .uppy-DragDrop-inner {
    padding: 0;
    text-align: center;
    cursor: pointer;
}

.t_m_upload .uppy-DragDrop-inner svg {
    display: none !important;
}

.t_m_upload .uppy-DragDrop-inner:before {
    content: '\f030';
    font-family: "font awesome 6 pro";
    font-size: 26px;
    margin-bottom: 10px;
    display: block;
    font-weight: 200;
}

.t_m_upload .uppy-DragDrop-label {
    font-size: 14px;
    font-weight: 600;
    color: #2b2b2b;
    text-align: center;
    margin-bottom: 10px;
    line-height: normal;
}

.t_m_upload .uppy-DragDrop-note {
    color: #929292;
    font-size: 11px;
    display: block;
    text-align: center;
    line-height: normal;
}

.t_m_pb_wrap {
    height: 3px;
    background-color: rgba(var(--ts-progress-color-rgb), .1);
    width: 100%;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
}

.t_m_pb {
    width: 50%;
    height: 100%;
    position: relative;
    background-color: var(#000)
}

.t_m_up_img_wrap {
    height: 76px;
    width: 76px;
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
}

.t_m_up_img_in {
    position: relative;
    height: 100%;
    width: 100%;
}

.t_m_up_img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border: 1px solid #BABFC3;
    border-radius: 3px;
}

.t_m_vi_ico {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #fff;
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, .3));
}

.t_m_remove_btn {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #ff4051;
    height: 18px;
    width: 18px;
    font-size: 10px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.t_from_check:checked {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E");
    background-color: #2b2b2b;
}

.t_f_check_inline {
    display: flex;
    align-items: start;
}

.t_f_label_wrap {
    display: flex;
    flex-direction: column;
    justify-content: start;
    text-align: start;
    margin-bottom: 10px;
}

.t_text_center {
    text-align: center;
}

.t_f_label_wrap .t_check_label_ {
    margin-bottom: 5px;
}

.t_f_label_wrap .t_tc_link {
    color: #1154cc;
}

.t_check_label_ {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 8px;
    color: #2b2b2b;
    display: inline-block;
    cursor: pointer;
    user-select: none;
}

.t_row_ {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
}

.t_col_ {
    flex: 1 0 100%;
    padding: 0 10px;
}

.t_tc_link {
    font-weight: 500;
    text-decoration: underline;
    color: #2b2b2b;
    font-size: 12px;
}

.t_f_p_btn {
    background-color: #000;
    border: 0;
    border-radius: 3px;
    box-shadow: none;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    min-width: 100px;
    opacity: .95;
    outline: none;
    padding: 10px 12px;
    text-align: center;
    display: inline-block;
    width: 100%;
}

.t_f_p_btn:hover {
    opacity: 1;
}

.t_f_s_btn {
    font-size: 14px;
    color: #000;
    text-decoration: underline;
    cursor: pointer;
    display: inline-block;
    padding: 3px 10px;
}

.t_f_p_btn.t_f_btn_spinner {
    padding-right: 40px;
    opacity: 0.9;
    box-shadow: none;
    pointer-events: none;
    position: relative;
}

.t_f_btn_spinner:after {
    content: "";
    position: absolute;
    width: 20px;
    right: 12px;
    top: calc(50% - 11px);
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    border: 1.5px solid #ffffff;
    border-right: 2px solid transparent;
    animation: tb_onsite_animation-spinner 0.5s linear infinite;
}

.t_m_close_btn {
    color: #000;
    font-size: 14px;
    line-height: 16px;
    opacity: .5;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
    cursor: pointer;
    padding: 10px;
}

.t_m_close_btn:hover {
    opacity: .7;
}

.t_rate_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.t_star_ico {
    padding: 8px;
    text-align: center;
    background-color: #D9D9D9;
    margin: 4px;
    font-size: 20px;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
}

.t_star_ico_wrap.t_active_ .t_star_ico,
.t_star_ico_wrap.t_h_active_ .t_star_ico {
    background-color: #fbbc04;
}


.t_m_propgress {
    display: flex;
    align-items: center;
    justify-content: center;
}

.t_m_propgress #path_fr_circle {
    stroke: #545454;
}

.t_m_propgress svg text {
    fill: #545454;
}

.t_f_error {
    font-size: 11px;
    color: #ff4051;
    margin: 2px 0;
    line-height: normal;
}

.t_f_invalid {
    border: 1px solid #ff4051;
}

.t_f_invalid+.t_f_error {
    display: block;
}

.t_f_upload {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.t_m_upload .uppy-FileInput-btn {
    background-color: transparent;
    font-size: 0;
    border: none;
    height: 100%;
    width: 100%;
    cursor: pointer;
    font-family: 'WebSocialFonts' !important;
    transform: rotate(45deg);
}

.t_m_upload .uppy-FileInput-btn:before {
    content: "\e910";
    font-size: 22px;
    position: relative;
}

.t_m_upload .uppy-FileInput-container,
.t_m_upload .uppy-Container,
.t_m_upload .uppy-Root {
    height: 100%;
    width: 100%;
}

.t_s_wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 100px 0;
}

.t_s_title {
    font-size: 22px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 12px;
}

.t_s_desc {
    text-align: center;
    color: #545454;
    font-size: 14px;
}

.t_s_icon_ {
    margin: 15px auto;
    width: 70px;
    height: auto;
}

.t_f_close_wrap {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.t_overlay_ {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99998;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
}

.t_star_ico_wrap.t_h_active_ .fa-star {
    color: #fff;
}

span.switch input:empty~span:after {
    top: 4px;
}
.switch input:empty~span::after {
    top: 50% !important;
    transform: translate(0, -50%);
    margin-top: 0;
}

/* Onsite new modal end section*/

.write-with-ai {
    right: 10px;
    bottom: 15px;
    background: black;
    border-radius: 4px;
    font-size: 12px;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    line-height: normal;
    opacity: .95;
    pointer-events:none;
}

.write-with-ai:hover {
    /* opacity: 1; */
}

.write-with-ai span {
    margin-left: 3px;
}

@media(max-width:1580px) {
    .ra-tab span {
        padding: 7px 10px;
    }

    .review-action {
        width: 300px;
    }

    .ra-presha {
        width: calc(100% - 300px);
    }

    div.snapUpContainer {
        width: 55%;
    }

    div.review-share {
        width: 45%;
    }

    .qr-code-section {
        flex-direction: column;
    }

    .qr-rightsec {
        margin: 10px 0 0 0;
        text-align: center;
    }
}

@media(max-width:1440px) {
    .ra-tab span {
        padding: 5px;
        font-size: 13px;
    }

    .ra-tab-wrap>span:first-child svg {
        margin-top: -4px;
    }

    .ra-tab-wrap>span:nth-child(2) svg {
        margin-top: -5px;
    }

    .ra-tab span svg {
        margin-right: 3px;
    }

    .ra-actions {
        padding: 10px 15px;
    }
}

@media(max-width:1399px) {
    div.snapUpContainer {
        width: 60%;
    }

    div.review-share {
        width: 40%;
    }
}

@media(max-width:1320px) {
    .review-share {
        padding-right: 20px;
    }

    .ra-tab span {
        font-size: 12px;
    }

    .ra-tab span svg {
        max-height: 14px;
        margin-right: 0;
    }

    div.snapUpContainer {
        width: 60%;
    }

    div.review-share {
        width: 40%;
    }
}

@media(max-width:1199px) {
    .qr-code-section {
        flex-direction: column;
    }

    .qr-rightsec p {
        padding: 0;
    }

    .qr-code-section .canvas {
        height: 100px;
        width: 100px;
    }

    .review-action {
        width: 265px;
    }

    .ra-presha {
        width: calc(100% - 265px);
    }

    div.snapUpContainer {
        width: 62%;
    }

    div.review-share {
        width: 38%;
    }
}

@media(max-width:1090px) {
    .ra-tab-wrap {
        display: flex;
    }

    .ra-tab span {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .ra-tab-wrap>span:first-child svg,
    .ra-tab-wrap>span:nth-child(2) svg,
    .ra-tab-wrap>span svg {
        margin: 0 0 3px;
    }

    .ra-tab span em {
        line-height: normal;
    }

    .ra-formwrap .form-control input[type="text"],
    .ra-formwrap .form-control textarea {
        padding: 10px;
    }

    .review-action {
        width: 225px;
    }

    .ra-presha {
        width: calc(100% - 225px);
    }

    div.t_modal_container {
        padding: 0;
    }

    div.onsite_preview_inner {
        transform: scale(0.85);
    }

    .review-hub {
        height: calc(100vh - 135px);
    }
}

@media (min-width: 991px) {
    .t_col_ {
        flex: 1 0 0%;
    }
}

@media(max-width:800px) {
    .t_modal_container {
        padding: 0;
    }

    .review-share {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media(max-width:767px) {
    .review-hub {
        flex-direction: column;
    }

    .review-action,
    .review-share,
    .ra-presha {
        width: 100%;
    }

    .review-action {
        border-radius: 6px;
    }

    .ra-tab span {
        padding-top: 8px;
        padding-bottom: 8px;
        justify-content: center;
        flex-direction: row;
    }

    .ra-tab-wrap>span:first-child svg,
    .ra-tab-wrap>span:nth-child(2) svg,
    .ra-tab-wrap>span svg {
        margin: -2px 5px 0;
    }

    .review-hub {
        box-shadow: none;
    }

    .review-action {
        border: 1px solid #DBE1E5;
    }

    .review-share {
        border: 1px solid #DBE1E5;
        border-radius: 6px;
        padding: 15px;
    }

    .ra-presha {
        flex-direction: column;
    }

    .rss-content,
    div.snapUpContainer,
    div.review-share {
        width: 100%;
    }

    .review-hub {
        height: 100%;
    }

    .snapUpContainer {
        border: 1px solid #DBE1E5;
        border-radius: 6px;
        background: #fff;
        margin: 10px 0;
    }

    .qr-code-section {
        flex-direction: row;
    }

    .qr-rightsec {
        margin: 0 0 0 10px;
        text-align: left;
    }
}

@media(max-width:480px) {
    .onsite_preview_inner {
        transform: scale(0.9);
    }
}

@media (min-width: 476px) {
    .t_modal_content {
        padding: 30px;
        border-radius: 5px;
    }

    .t_modal_container {
        /* margin: 20px auto; */
        padding: 0 15px;
        margin-top: 0px;
    }
}

@media(max-width:374px) {
    .qr-code-section {
        flex-direction: column;
    }

    .qr-rightsec {
        margin: 10px 0 0 0;
        text-align: center;
    }
}
/* CustomerSupport-START */
.container-fluid.custom-gap {
    padding-left: 60px;
    padding-right: 60px;
}

.customer-support-main {
    border: 1px solid #637079;
    padding: 20px 30px;
}

.success-manager-info {
    background-color: #fff;
    padding-right: 10px;
}

.profile-bg {
    width: 227px;
}

.success-manager-info .tb_cp_image {
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    max-height: 172px;
    max-width: 227px;
    bottom: 0;
}

.customer-info h4 {
    color: #2b2b2b;
    font-size: 18px;
    font-weight: 600;
}

.customer-info p {
    color: #959595;
    font-size: 14px;
    font-weight: 400;
}

.customer-success-details {
    padding: 30px 0px 30px 30px;
    min-height: 167px;
}

.customer-info .btn-chat-now,
.customer-info .btn-book-call,
.customer-info .btn-chat-now:hover {
    background-color: #fff;
    color: #2b2b2b;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    height: 36px;
    line-height: normal;
    transition: all 0.3s ease-in-out;
    border-color: #707070;
}

.customer-info .btn-book-call,
.customer-info .btn-book-call:hover {
    background-color: #2b2b2b;
    color: #fff;
    border-color: transparent;
}

.customer-info .btn-chat-now:hover,
.customer-info .btn-book-call:hover {
    opacity: 0.8;
}

.profile-name {
    color: #959595;
    font-size: 14px;
    font-weight: 400;
}

.profile-message {
    font-size: 13px;
    font-weight: 400;
    color: #2b2b2b;
    max-width: 392px;
}

.profile-datalink {
    font-size: 14px;
    color: #2B2B2B;
    font-weight: 500;
    text-decoration: underline;
}

.profile-datalink:hover {
    text-decoration: none;
}

/* CustomerSupport-END */
/* AccountInfo-START */
.info-card {
    padding: 15px;
    box-shadow: 0px 0px 9px 0px #94AEC52E;
}

.info-card .ci-top span,
.info-card .ci-top span i {
    font-size: 14px;
    font-weight: 500;
    color: #6D7175;
}

.info-card .ci-top .btn-upgrade {
    font-size: 12px;
    color: #579A48;
    font-weight: 500;
    padding: 4px 12px;
    border-radius: 4px;
    border: 1px solid #579A48;
}

.info-card .ci-top .btn-upgrade:hover {
    color: #fff;
    cursor: pointer;
    background-color: #579A48;
}

.info-card .ci-top .btn-upgrade:hover {
    cursor: pointer;
}

div.tooltip .tooltip-arrow::before {
    top: -5px;
}

.info-card .ci-mid strong {
    font-size: 26px;
    font-weight: 500;
    margin-right: 10px;
    color: #1A232F;
    line-height: normal;
    display: flex;
    align-self: baseline;
}

.info-card .ci-mid span {
    font-size: 14px;
    color: #637079;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-self: baseline;
}

.ci-mid em {
    font-size: 12px;
    font-weight: 400;
}

.info-card .ci-bottom {
    margin-top: 10px;
}

.card.info-card .ci-ctr {
    font-size: 14px;
    color: #637079;
    font-weight: 500;
}

.card.info-card .ci-ctr i {
    font-size: 15px;
    font-weight: 600;
    margin-left: 4px;
    color: #FF4050;
    font-style: normal;
}

/* AccountInfo-END */

/* ProgressBar-START */
.ci-bottom .progress-bar.progress-bar-danger {
    background-color: #FF4050;
}

.ci-bottom .progress-bar.progress-bar-warning {
    background-color: #f5ad66;
}

/* ProgressBar-END */

/* PerformingWidget-START */
.performing-widget,
.recent-post-section,
.dashboard-mac .card.card-custom {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 9px 0px #94AEC52E;
}

.performing-widget {
    min-height: 382px;
}

.recent-post-section {
    padding-bottom: 0px !important;
}

.performing-widget .behind-blur {
    height: calc(100% - 65px);
}

.recent-post-section .behind-blur {
    height: calc(100% - 10px);
}

.performing-widget .behind-blur+div img,
.recent-post-section .behind-blur+div img {
    width: 100%;
    max-height: 312px
}

.recent-post-section .behind-blur+div img {
    height: 280px;
}

.dashboard-mac .custom-tabalign #sentiment-tabpane-reputation .behind-blur {
    height: 100%;
    top: 50%;
}

.dashboard-mac .custom-tabalign #sentiment-tabpane-reputation .behind-blur+div img {
    width: 72%;
    max-height: 312px;
}

.dashboard-mac .custom-tabalign #sentiment-tabpane-posts .behind-blur {
    height: calc(100% - 65px);
    top: calc(50% + 30px);
}

.dashboard-mac .custom-tabalign #sentiment-tabpane-posts .behind-blur+div img {
    width: 80%;
    max-height: 312px;
}

.dashboard-mac .card.card-custom.TopPerformancePosts .behind-blur {
    height: calc(100% - 60px);
}

.dashboard-mac .card.card-custom.TopPerformancePosts .behind-blur+div img {
    max-height: 312px;
    width: 99%;
}

.performing-widget .view-all,
.performing-widget .view-all:active,
.performing-widget :not(.btn-check)+.btn.view-all:active {
    color: #637079;
    font-size: 12px;
    font-weight: 500;
    background-color: transparent;
    border-color: transparent;
}

.performing-widget .view-all:hover {
    color: #ff4051;
}

button#wallswitcherDropdownMenu {
    border-color: #2B2B2B !important;
    padding: 8px 10px !important;
}

a#preview_button {
    letter-spacing: 1px;
    color: #1A232F;
}

a#initPublishPopupId {
    letter-spacing: 1px;
}

.aside-menu .menu-nav>.wall_switcher.menu-item:first-child:hover {
    background-color: transparent;
}

.widget-chper>span {
    margin-left: 0px;
}

.performing-widget .per-head h2,
.recent-post-section .per-head h2 {
    font-size: 16px;
    color: #1A232F;
    font-weight: 600;
}

.performing-widget .create-widget {
    border: none;
    cursor: pointer;
    color: #637079;
    padding: 6px 10px;
    border-radius: 4px;
    background-color: #F4F4F6;
}

div.performing-widget .create-widget:active,
div.performing-widget .create-widget:hover {
    color: #fff;
    background-color: #1A232F;
    box-shadow: 0 0 0 1px inset #000;
}

div.performing-widget .create-widget:active i,
div.performing-widget .create-widget:hover i {
    color: #fff;
}

.performing-widget .create-widget.create-widget-center {
    position: absolute;
    left: 0;
    right: 0;
    width: calc(100% - 3rem);
    margin: 0 auto;
    padding: 30px;
    border: 1px solid #DCE0E3;
    background: #fff;
    justify-content: center;
    color: #637079;
    font-size: 16px;
    font-weight: 400;
    top: calc(50% + 8px);
    z-index: 1;
    transition: all 0.3s ease-in-out;
}

.performing-widget .create-widget.create-widget-center:hover {
    background: #f4f4f6;
    color: #1A232F;
    box-shadow: none;
}

.performing-widget .create-widget.create-widget-center:hover i {
    color: #1A232F;
}

.performing-widget .create-widget.create-widget-center>span {
    line-height: normal;
}

.recent-post-section .tb_modal_slider_dots {
    display: none;
}

.recent-post-section .custom-prev[disabled],
.recent-post-section .custom-next[disabled],
.dashboard-mac .TopPerformancePosts .custom-prev[disabled],
.dashboard-mac .TopPerformancePosts .custom-next[disabled] {
    opacity: 0.75;
    pointer-events: none;
}

.recent-post-section .custom-slide-arrow-mod,
.recent-post-section .custom-slide-arrow-mod:hover,
.dashboard-mac .TopPerformancePosts .custom-slide-arrow-mod,
.dashboard-mac .TopPerformancePosts .custom-slide-arrow-mod:hover {
    box-shadow: none;
}

.recent-post-section .recentPostSliderWrap {
    min-height: 324px;
    max-height: 330px;
    padding-bottom: 0px !important;
}

/* Table */

.performing-widget table {
    margin-top: 15px;
    width: 100%;
    border-collapse: collapse;
}

.performing-widget thead th {
    font-size: 14px;
    font-weight: 500;
    color: #637079;
    white-space: nowrap;
}

.performing-widget thead th:nth-child(2),
.performing-widget thead th:nth-child(3),
.performing-widget thead th:nth-child(4),
.performing-widget tbody td:nth-child(2),
.performing-widget tbody td:nth-child(3),
.performing-widget tbody td:nth-child(4) {
    text-align: center;
}

.performing-widget thead th:first-child {
    padding-left: 0;
}

.performing-widget th,
.performing-widget td {
    padding: 13px 10px;
    border-bottom: 1px solid #DCE0E3;
}

.performing-widget td:first-child {
    padding-left: 0;
}

.performing-widget th:last-child {
    white-space: nowrap;
    padding-right: 0px;
    padding-left: 40px;
}

.performing-widget td:last-child {
    text-align: right;
    padding-right: 0px;
}

/* Widget Info */
.performing-widget .widget-info {
    display: flex;
    align-items: center;
}

.performing-widget .widget-thumbnail {
    width: 56px;
    height: 56px;
    padding: 3px;
    margin-right: 10px;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #DCE0E3;
}

.performing-widget .widget-details {
    width: calc(100% - 66px);
}

.performing-widget .widget-details span {
    opacity: 0.67;
    display: block;
    font-size: 12px;
    color: #6D7175;
    font-weight: 400;
}

.widget-chper strong {
    font-size: 14px;
    font-weight: 600;
    color: #1A232F;
    margin-bottom: 3px;
}

.widget-chper .an-up,
.widget-chper .an-down {
    background: #E1F8F2;
    color: #02A97E;
    padding: 0 2px 0 4px;
    border-radius: 2px;
}

.widget-chper .an-down {
    background: #FFE6E7;
    color: #F8434C;
    ;
}

.performing-widget .widget-details strong {
    font-size: 14px;
    font-weight: 500;
    color: #202223;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

/* Positive and Negative Styling */
/* Network Icons */
.performing-widget .network-icons img {
    width: 20px;
    margin-right: 5px;
}

/* Buttons */
.performing-widget .action-btn {
    border: none;
    cursor: pointer;
    font-size: 13px;
    min-width: 73px;
    padding: 6px 10px;
    margin-right: 5px;
    border-radius: 4px;
}

.performing-widget .moderate {
    color: #000000;
    background-color: #F4F4F6;
}

.recentActions .btn {
    font-size: 14px;
    font-weight: 500;
    color: #637079;
    border-radius: 4px;
    padding: 8px 15px 9px;
    background-color: #F4F4F6;
    line-height: normal;
}

.performing-widget .moderate,
.performing-widget .action-btn,
.performing-widget :not(.btn-check)+.btn:active,
.performing-widget .btn.active {
    font-weight: 400;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
    color: #000;
    background-color: #F4F4F6;
}

.recentActions .btn:hover,
.recentActions .btn.active,
.performing-widget .action-btn:hover,
.performing-widget .action-btn.active,
.te-theme .recentActions .btn:hover,
.te-theme .recentActions .btn.active,
.te-theme .performing-widget .action-btn:hover,
.te-theme .performing-widget .action-btn.active {
    color: #fff;
    border-color: transparent;
    background-color: #1A232F;
}

.te-theme .recentActions .btn.active,
.te-theme .recentActions .btn:first-child:active,
.te-theme .recentActions .btn.active,
.te-theme .recentActions :not(.btn-check)+.btn:active {
    color: #fff !important;
    border-color: transparent !important;
}

.recentPostSliderWrap .splide__arrows,
.dashboard-mac .TopPerformancePosts .splide__arrows {
    display: flex;
    margin-top: 10px;
    justify-content: center;
}

.recentPostSliderWrap .splide__arrows .custom-slide-arrow-mod,
.dashboard-mac .TopPerformancePosts .splide__arrows .custom-slide-arrow-mod {
    left: inherit;
    transform: none;
    margin-right: 5px;
    position: relative;
}

.recentPostSliderWrap .splide__arrows .custom-slide-arrow-mod.custom-next,
.dashboard-mac .TopPerformancePosts .splide__arrows .custom-slide-arrow-mod.custom-next {
    right: inherit !important;
}

.eventHighlighter {
    border-radius: 22px;
    background-color: #f6f9fb;
}

.item-post {
    border-radius: 4px;
    border: 1px solid #DCE0E3;
}

.item-post .con_user_img span {
    width: 42px;
    height: 42px;
}

.item-post .author_name {
    color: #000;
    font-size: 13px;
    font-weight: 500;
}

.item-post .conn_auther_info small {
    max-width: 100px;
    width: auto;
    font-size: 9px;
    color: #959595;
    font-weight: 500;
}

.item-post .conn_auther_info small.saprator-dot {
    background: #959595;
    width: 2px;
    height: 2px;
    margin-top: 8px;
    border-radius: 50%;
}

.item-post .item-content {
    font-size: 12px;
    font-weight: 500;
    color: #637079;
}

.item-post .item-type {
    font-size: 13px;
    font-weight: 400;
    color: #959595;
}

.item-post .item-widgetname {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-size: 12px;
    max-width: 158px;
    font-weight: 500;
    color: #1A232F;
    padding: 7px 15px;
    border-radius: 22px;
    background-color: #F6F9FB;
}

.item-post .m_bottom_actions ul li .btn {
    font-size: 13px;
    font-weight: 500;
}

.item-post .m_bottom_actions .btn {
    color: #535353;
    font-size: 13px;
    font-weight: 500;
    background-color: #fff;
    border: 1px solid #E4E4E4;
}

.item-post .m_bottom_actions .btn-public.disabled,
.item-post .m_bottom_actions .btn-public:hover {
    background-color: #42C962;
    border: 1px solid #42C962;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
}

.item-post .m_bottom_actions .btn-private.disabled,
.item-post .m_bottom_actions .btn-private:hover {
    background-color: #db4b20;
    color: #fff;
    border-color: #db4b20;
}

.row .item-post-left {
    flex: 0 0 auto;
    width: 164px;
    height: 244px;
}

.recent-post-section .item-without-image {
    width: 342px !important;
}

.recent-post-section .item-without-image .item-post-left {
    display: none;
}

.recent-post-section .item-without-image .item-post-right {
    width: 100% !important;
}

.custom-summary-cards .svg-icon.svg-icon-dark-75 svg g [fill] {
    fill: #959595 !important;
}

.row .item-post-left .home_recentPost>div {
    background: #F4F4F6;
    border-radius: 4px;
}

.row .item-post-left .home_recentPost .tb_video__ {
    height: 100% !important;
}

.row .item-post-left .home_recentPost .tb_video__ iframe,
.custom-splide-item .tb_video__ iframe {
    border-radius: 4px;
}

.row .item-post-left .home_recentPost img,
.row .item-post-left .home_recentPost .tb_video__ video {
    object-fit: cover;
    height: 100%;
    border-radius: 4px;
}

.row .item-post-left .home_recentPost img[src="https://cloud.tagbox.com/react-app/media/images/no-image.svg"] {
    object-fit: cover !important;
    height: 100% !important;
    width: 100% !important;
    margin: 0 !important;
    border-radius: 4px !important;
}

.row .item-post-right {
    flex: 0 0 auto;
    padding-left: 5px;
    width: calc(100% - 164px);

}

/* PerformingWidget-END */

/* Skeleton-START */
.sk_line {
    background: #d9d9d9;
    border-radius: 30px;
    height: 12px;
    margin: 10px 0;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.sk_line.sk_line_height_10 {
    height: 10px;
}

.sk_line.sk_line_80 {
    width: 80%;
}

.wall_progress h3 {
    font-size: 26px;
    font-weight: 600;
}

.sk_line.sk_line_height_26 {
    height: 26px;
}

.sk_line.sk_line_40 {
    width: 40%;
}

/*  */
.social_user_img span {
    background-color: #f7f7f7;
    background-size: cover;
    border-radius: 4px;
    display: block;
    height: 20px;
    width: 20px;
}

.sk_img {
    border-radius: 50%;
    height: 75px;
    width: 75px;
}

.sk_img,
.sk_rect {
    background: #d9d9d9;
    background-color: #d9d9d9 !important;
    overflow: hidden;
    position: relative;
}

/*  */

.sk_img:before,
.sk_line:before,
.sk_rect:before {
    animation: shimmer 1s linear infinite;
    background-image: linear-gradient(90deg, #d9d9d9 0, #0000000d 20%, #d9d9d9 40%, #d9d9d9);
    background-repeat: no-repeat;
    background-size: 450px 400px;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

@keyframes shimmer {
    0% {
        background-position: -450px 0;
    }

    100% {
        background-position: 450px 0;
    }
}

/* Skeleton-END */

.dashboard-mac>div>.card.card-custom {
    padding: 1.5rem;
}

.dashboard-mac .card.card-custom .nav-tabs-line {
    height: auto;
}

.dashboard-mac .card.card-custom .cutsom-tab-viewall,
.dashboard-mac .card-custom.custom-tabalign .nav-tabs {
    padding-left: 0 !important;
    padding-right: 0;
}

.dashboard-mac .cutsom-tab-viewall,
.dashboard-mac .card-custom.custom-tabalign .nav-tabs {
    border-color: #DCE0E3;
}

.dashboard-mac .cutsom-tab-viewall .nav-link.active {
    box-shadow: none;
}

.dashboard-mac .nav.nav-tabs.nav-tabs-line .nav-link {
    padding: 5px 0 10px;
}

.dashboard-mac .nav-item button>div,
.dashboard-mac .custom-tabalign.card-custom .nav-item button#sentiment-tab-reputation,
.dashboard-mac .custom-tabalign.card-custom .nav-item button#sentiment-tab-posts {
    color: #637079;
    font-size: 16px;
    font-weight: 500;
}

.dashboard-mac .nav-item button.active>div,
.dashboard-mac .custom-tabalign.card-custom .nav-item button#sentiment-tab-reputation.active,
.dashboard-mac .custom-tabalign.card-custom .nav-item button#sentiment-tab-posts.active {
    color: #1A232F;
    font-weight: 600;
}

.dashboard-mac .cutsom-tab-viewall .nav-link.active .see_more_btn {
    right: 1.5rem;
    top: 28px;
    color: #637079;
    font-size: 12px;
    font-weight: 500;
}

.dashboard-mac #creators-tabpane-activeUsers .card-body,
.dashboard-mac #creators-tabpane-topInfluencers .card-body {
    overflow-y: auto;
    max-height: 264px;
    min-height: 264px;
}

.dashboard-mac #sentiment-tabpane-posts .aligncustom>div+div {
    margin-top: 15px;
}

.dashboard-mac #sentiment-tabpane-posts .aligncustom .dashboard-mac .tab-content .card-custom .card-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.dashboard-mac .tab-content .card.card-custom {
    padding-top: 15px;
    padding-bottom: 15px;
}

.dashboard-mac .con_user_img span {
    width: 43px;
    height: 43px;
}

.dashboard-mac .author_name {
    font-size: 14px !important;
    color: #202223 !important;
    font-weight: 500 !important;
    margin-bottom: 3px !important;
}

.dashboard-mac .users_row {
    border-bottom: 1px solid #DCE0E3;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
}

.dashboard-mac .conn_user_info .post_count {
    color: #6D7175 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    opacity: 0.67;
    display: inline-block;
    max-width: 90%;
}

.dashboard-mac .list_category_icon .post_count {
    font-size: 14px;
    color: #637079;
    font-weight: 500;
}

.dashboard-mac .list_category_icon i {
    font-size: 18px;
}

.dashboard-mac .conn_status_wrap .btn-secondary {
    background: #F4F4F6;
    color: #202223;
    font-weight: 500;
    font-size: 14px;
    border: 0;
    padding: 9px 10px;
}

.dashboard-mac .users_row:hover {
    background-color: transparent;
}

.dashboard-mac .conn_user_info {
    margin-left: 2px;
}

.dashboard-mac .rating-summary h1 {
    font-size: 46px;
    font-weight: 500;
    color: #333;
}

.dashboard-mac .rating-breakdown {
    margin: -5px 0;
}

.dashboard-mac .rating-summary p {
    font-size: 14px;
    color: #1A232F;
    font-weight: 400;
}

.dashboard-mac .review-count {
    font-size: 14px;
    color: #1A232F;
    font-weight: 500 !important;
}

.dashboard-mac .rating-row>span:first-child {
    font-size: 14px;
    font-weight: 500;
    color: #637079;
    width: 46px;
    text-align: left;
}

.dashboard-mac .rating-row>span:last-child {
    color: #1A232F;
    font-weight: 600;
    font-size: 14px;
    width: 100px;
    text-align: left;
    padding-left: 5px;
}

.dashboard-mac .m_rating span {
    color: #FBBC05;
}

.dashboard-mac .rating-row {
    margin-bottom: 20px;
}

.dashboard-mac .rating-row .bar {
    border-radius: 0;
}

.dashboard-mac .rating-row .bar .filled {
    background: #FBBC05;
    border-radius: 0;
}

.dashboard-mac .cta {
    font-size: 14px;
    font-weight: 500;
    color: #637079;
    margin-top: 25px;
    margin-bottom: 0;
    text-align: left;
}

.dashboard-mac .cta a {
    color: #FF4050 !important;
    font-weight: 500 !important;
}

.te-theme .dashboard-mac .cta a {
    color: #4179ff !important;
}

.dashboard-mac .rating-container {
    padding: 10px 0;
}

.dashboard-mac .rating-container .behind-blur+div {
    text-align: left;
}

.dashboard-mac .card.card-custom.TopPerformancePosts .card-header {
    padding: 0 !important;
    border: 0;
}

.dashboard-mac .card.card-custom.TopPerformancePosts>.card-header .card-title .card-label {
    font-weight: 600;
    font-size: 16px;
    color: #1A232F;
}

.dashboard-mac .card.card-custom.TopPerformancePosts .card-body {
    padding: 15px 0;
}

.dashboard-mac .card.card-custom.TopPerformancePosts .top_posts_row>div {
    padding-left: 0;
    padding-right: 0;
}

.dashboard-mac .card.card-custom.TopPerformancePosts .custom-splide-item img {
    border-radius: 4px;
    width: 208px;
    height: 208px;
}

.dashboard-mac .card.card-custom.TopPerformancePosts .custom-splide-item {
    padding: 10px;
}

.dashboard-mac .card.card-custom.TopPerformancePosts .con_user_img span {
    width: 34px;
    height: 34px;
}

.dashboard-mac .card.card-custom.TopPerformancePosts .cs-detail p:first-child {
    font-size: 13px;
    font-weight: 600;
    line-height: normal;
    color: #1A232F;
    margin-bottom: 1px !important;
    overflow: hidden;
    width: 118px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dashboard-mac .card.card-custom.TopPerformancePosts .splide__slide {
    width: 226px !important;
}

.dashboard-mac .card.card-custom.TopPerformancePosts .cs-detail span {
    color: #637079;
    font-weight: 500;
    font-size: 9px;
}

.dashboard-mac .card.card-custom.TopPerformancePosts .postContentMain p {
    font-weight: 400;
    color: #637079;
    font-size: 12px;
}

.dashboard-mac .card.card-custom.TopPerformancePosts .custom-splide-cont span {
    color: #202223 !important;
    font-size: 13px;
    font-weight: 500 !important;
    opacity: 0.67;
    width: 50%;
}

.dashboard-mac .card.card-custom.TopPerformancePosts .custom-splide-cont p {
    color: #1A232F !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    width: 86px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom-summary-cards .card {
    padding: 1.5rem;
    border-radius: 8px;
    padding-top: calc(1.5rem - 3px);
    border-top: 3px solid transparent;
    box-shadow: 0px 0px 9px 0px #94AEC52E;
}

.custom-summary-cards .card.card-line-1 {
    border-top-color: #5E78F4;
}

.custom-summary-cards .card.card-line-2 {
    border-top-color: #ED2FE0;
}

.custom-summary-cards .card.card-line-3 {
    border-top-color: #FE8900;
}

.custom-summary-cards .card.card-line-4 {
    border-top-color: #FEC600;
}

.custom-summary-cards .card .ci-top {
    min-height: 28px;
}

.custom-summary-cards .card .ci-mid {
    margin-top: 7px;
    margin-bottom: 7px;
}

.custom-summary-cards .progress {
    height: 10px;
}

.info-card .ci-mid span.an-change {
    padding: 3px 5px;
    align-self: auto;
}

.info-card .ci-mid span.an-up {
    background: #E1F8F2;
    color: #02A97E;
}

.info-card .ci-mid span.an-down {
    background: #FFE6E7;
    color: #F8434C;
}

.custom-top-performing .modal-header {
    box-shadow: none;
    border-bottom: 1px solid #E8EBED;
}

.custom-top-performing .max-img img {
    border-radius: 4px;
    min-height: 534px;
    max-height: 534px;
}

.custom-top-performing .max-img .tb_video__ {
    height: 100% !important;
    max-height: 534px;
}

.custom-top-performing .max-img .tb_video__ video {
    border-radius: 4px;
    object-fit: cover;
}

.custom-top-performing .con_user_img span {
    height: 50px;
    width: 50px;
}

.custom-top-performing .cs-detail {
    margin-left: 0;
}

.custom-top-performing .cs-detail p:first-child {
    font-size: 16px;
    font-weight: 600;
    color: #1A232F;
}

.custom-top-performing .cs-detail p+p {
    font-size: 14px;
    font-weight: 400;
    color: #959595;
}

.custom-top-performing .custom-splide-autsec i {
    font-size: 24px !important;
}

.custom-top-performing .gi-content p {
    color: #637079;
    font-size: 13px;
    font-weight: 400;
    word-wrap: break-word;
}

.custom-top-performing .gi-sentiment p:first-child {
    color: #637079;
    font-size: 13px;
    font-weight: 500;
}

.custom-top-performing .gi-sentiment p strong {
    font-size: 13px;
    color: #1A232F;
    font-weight: 600;
}

div.custom-top-performing .btn-connect,
div.custom-top-performing .btn-connect:hover {
    font-size: 16px;
    font-weight: 500;
}

.custom-top-performing .modal-body {
    padding: 1.75rem 1.75rem;
}

.influential_contributors_custom_modal .symbol .symbol-label,
.influential_contributors_custom_modal .profimg-custom>span {
    width: 50px;
    height: 50px;
}

.influential_contributors_custom_modal .user_info h6,
.influential_contributors_custom_modal .user_profile_items_title strong {
    font-size: 16px;
    font-weight: 600;
    color: #1A232F;
    margin-bottom: 3px;
}

.influential_contributors_custom_modal .user_info h6+p,
.influential_contributors_custom_modal .user_profile_items_title span {
    color: #959595;
    font-size: 14px;
    font-weight: 400;
    opacity: 1;
}

.influential_contributors_custom_modal .user_profile_disc p {
    color: #637079;
    font-size: 13px;
    font-weight: 400;
}

.influential_contributors_custom_modal .btn-btn {
    font-size: 16px;
    font-weight: 500;
}

.influential_contributors_custom_modal .symbol {
    margin-right: 10px !important;
}

.influential_contributors_custom_modal .card {
    border: 1px solid #E0E0E0;
}

.influential_contributors_custom_modal .user_profile_items img {
    border-radius: 4px;
    width: 179px !important;
    height: 181px !important;
}

.influential_contributors_custom_modal .custom-profile-items {
    width: 25%;
}

.influential_contributors_custom_modal .custom-profile-items+.user_profile_items_info {
    width: 75%;
    padding-left: 10px !important;
}

.influential_contributors_custom_modal .user_profile_items_info {
    width: 100%;
}

.influential_contributors_custom_modal .headerActiveCreator {
    margin-bottom: 5px;
}

.influential_contributors_custom_modal .user_profile_data p span {
    font-size: 13px;
    font-weight: 500;
    color: #637079;
}

.influential_contributors_custom_modal .user_profile_data p strong {
    color: #1A232F;
    font-weight: 600;
    font-size: 13px;
}

.influential_contributors_custom_modal .user_profile_items_info>div>i {
    font-size: 24px;
}

.paginatioPopup .shwoing-filter>span {
    font-size: 14px;
    font-weight: 500;
    color: #626B76;
}

div.paginatioPopup .shwoing-filter>select {
    background: #fff !important;
    border: 1px solid #BBBFC3 !important;
    color: #202223;
    font-size: 14px;
    font-weight: 500;
}

div.paginatioPopup .page-item .page-link {
    background-color: #EFF2F5;
    border-radius: 6px;
    color: #626B76;
    font-weight: 500;
}

div.paginatioPopup .page-item span[aria-hidden="true"] {
    font-size: 20px;
}

div.paginatioPopup {
    border-top: 1px solid #E8EBED;
    margin-left: 0;
    margin-right: 0;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
    margin-top: 10px;
}

div.modal .modal-header .modal-title {
    font-size: 16px;
    font-weight: 600;
    color: #1A232F;
}

.influential_contributors_custom_modal .headerActiveCreator>div>span {
    color: #959595;
    font-size: 14px;
    font-weight: 500;
}

.influential_contributors_custom_modal .before-search,
.influential_contributors_custom_modal .users_row>div:nth-child(3) {
    padding-left: 22px;
}

.before-search+div {
    width: 40%;
}

.performing-widget.create-widget-center,
.performing-widget.two-widget-center {
    min-height: 300px;
}

.performing-widget.create-widget-center .create-widget.create-widget-center {
    top: calc(50% + 48px);
}

.dashboard-mac .custom-tabalign .rating-breakdown {
    max-width: 500px;
}

.dashboard-mac .behind-blur h3 {
    color: #1A232F;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}

.dashboard-mac .behind-blur p {
    letter-spacing: 0;
}

.user_details_section.profile_with_users_list .row>.max-img,
.user_details_section.profile_with_users_list .row>.max-img+div {
    width: 50%;
}

.user_details_section.profile_with_users_list .row>div {
    width: 100%;
}

.dashboard-mac .custom-tabalign .sentimentupgrade .text-capitalize {
    width: calc(100% - 130px);
}

.dashboard-mac .custom-tabalign .sentimentupgrade .text-primary {
    white-space: nowrap;
}

.recommdation-box {
    left: 4%;
    bottom: 10px;
    height: 22px;
    width: 26px;
    color: #fff;
    margin: 0 auto;
    font-size: 0;
    font-weight: 500;
    position: absolute;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, .42);
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
}

.recommdation-box em {
    width: 10px;
    height: 10px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
}

.recommdation-box i {
    color: #fff;
    font-size: 0;
    font-weight: 500;
    font-style: normal;
}

.recentPostSliderWrap .item-post,
.recentPostSliderWrap .item-post:hover {
    transition: all 0.5s ease-in-out;
}

.recentPostSliderWrap .item-post:hover .recommdation-box {
    font-size: 10px;
    width: 92%;
}

.recentPostSliderWrap .item-post:hover .recommdation-box em {
    margin-right: 5px;
}

.recentPostSliderWrap .item-post:hover .recommdation-box i {
    font-size: 10px;
}

.top_posts.top_posts_new .custom-splide-item,
.top_posts.top_posts_new .custom-splide-item .m_card_cont,
.top_posts.top_posts_new .custom-splide-item .postContentMain {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}

.sigle-post-popup .modal-dialog {
    max-width: 500px;
}

.dashboard-mac .aligncustom .chart-bg {
    top: 20px
}

.recent-post-section .home_recentPost .tb_video__ .fb-video>span {
    height: 100% !important;
}

.recent-post-section .home_recentPost .tb_video__ .fb-video>span iframe {
    height: 100% !important;
    object-fit: cover !important;
    min-width: 100% !important;
    max-height: 100%;
    min-height: 100%;
}

.user_details_section.profile_with_users_list .gi-content {
    max-height: 375px;
    overflow-y: auto;
}

.gi-sentiment .widget-name>div {
    justify-content: flex-start !important;
}

.gi-sentiment .widget-name>div>span {
    width: 120px;
}

.gi-sentiment .widget-name>div>.item-widgetname {
    font-size: 13px;
    font-weight: 500;
    color: #1A232F;
}

#initPublishPopupId:active .sidebar_btn_txt {
    color: #ff4051;
}

.TopPerformancePosts .tb_post_modal_media_slider,
.dashboard-mac .tb_post_modal_media_slider {
    position: inherit !important;
}

.dashboard-mac .tb_post_modal_arrow[disabled] {
    display: flex !important;
}

.TopPerformancePosts .top_posts_row .top_posts,
.dashboard-mac .top_posts_row .top_posts,
.dashboard-mac .top_posts_row .top_posts.top_posts_new {
    flex: 0 0 100% !important;
    max-width: 100% !important;
}
.modal-custom-error-popup {
    max-width: 340px !important;
}
.error-message {
    /* padding: 0px 0px 50px 0; */
    padding: 0px 0px 1px 0;
}
.error-message p {
    font-size: 16px;
    font-weight: 500;
    color: #1A232F;
    margin: 0 auto;
}

.performing-widget thead th,
.performing-widget tbody td {
    width: 12.5%;
}
.performing-widget thead th:first-child,
.performing-widget tbody td:first-child {
    width:30%;
}
.performing-widget thead th:last-child,
.performing-widget tbody td:last-child {
    width: 20%;
}

@media(min-width:1601px) {
    .performing-widget th:last-child {
        text-align: center;
        padding-left: 0px;
    }
}

@media(max-width:1600px) {
    .recent-post-section .recentPostSliderWrap {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .success-manager-info .tb_cp_image {
        max-width: 137px;
    }

    .dashboard-mac .conn_user_info {
        max-width: 170px;
    }
}

@media(max-width:1400px) {
    .info-card .ci-top .btn-upgrade {
        padding: 4px 5px;
    }
    .custom-summary-cards .card {
        padding: calc(1.0rem - 3px) 1.0rem 1.5rem;
    }
}

@media(max-width:1366px) {
    .performing-widget th:last-child {
        padding-left: 25px;
    }

    .success-manager-info .tb_cp_image {
        max-width: 130px;
    }
}

@media(max-width:1435px) and (min-width:1280px) {
    .performing-widget .flex-mob {
        /* white-space: nowrap;
        display: flex; */
    }
    .performing-widget .flex-mob a, 
    .performing-widget .flex-mob button.action-btn {
        /* padding-left: 10px;
        padding-right: 10px; */
    }
    /*  */
    .performing-widget thead th,
    .performing-widget tbody td {
        width: 10%;
    }
    .performing-widget thead th:first-child,
    .performing-widget tbody td:first-child {
        width:30%;
    }
    .performing-widget thead th:last-child,
    .performing-widget tbody td:last-child {
        width:22%;
    }
    /*  */
}
@media(max-width:1279px) {
    .container-fluid.custom-gap {
        padding-left: 40px;
        padding-right: 40px;
    }

    .customer-support-main {
        padding: 15px 20px;
    }

    div.performing-widget th:last-child {
        text-align: right;
        padding-left: 0;
        padding-right: 130px;
    }

    .flex-mob {
        display: flex;
        justify-content: flex-end;
    }

    .dashboard-mac .conn_user_info {
        max-width: 120px;
    }
}

@media(max-width:1199px) {
    .customer-support-main {
        padding: 15px;
    }

    .customer-success-details {
        padding: 20px 0px 20px 20px;
    }

    .container-fluid.custom-gap {
        padding-left: 25px;
        padding-right: 25px;
    }

    .custom-summary-cards .card {
        padding-top: 1.0rem;
    }

    .performing-widget th:last-child {
        text-align: left;
    }

    .dashboard-mac>.col-lg-6 {
        width: 100%;
    }

    .recentPostSliderWrap li.splide__slide {
        width: 410px !important;
    }
}
@media(max-width:1150px) and (min-width:992px) {
    .custom-summary-cards .card .ci-top {
        flex-wrap: wrap;
    }
}
@media(max-width:991px) {
    .customer-success-details {
        padding: 15px 0px 15px 20px;
    }

    .after_intro_screen .tab-content {
        min-height: 337px;
    }

    .modal.show .modal-dialog {
        max-width: 96%;
    }

    .influential_contributors_custom_modal .influential-data-list .users_row {
        display: flex !important;
        width: auto;
    }
}

@media(max-width:767px) {
    .customer-support-main>div>.col-xl-6 {
        width: 100%;
    }

    .customer-support-main>div>.col-xl-6+div {
        margin-top: 5px;
    }

    .customer-info {
        display: flex;
        justify-content: space-between;
    }

    .customer-success-details {
        padding: 15px 0px 15px 15px;
    }

    .flex-mob {
        display: flex;
    }

    .performing-widget .per-head h2,
    .recent-post-section .per-head h2,
    .dashboard-mac .nav-item button>div,
    .dashboard-mac .custom-tabalign.card-custom .nav-item button#sentiment-tab-reputation,
    .dashboard-mac .custom-tabalign.card-custom .nav-item button#sentiment-tab-posts {
        font-size: 14px;
    }
}

@media(max-width:640px) {

    .influential_contributors_custom_modal .headerActiveCreator>div>span {
        font-size: 13px;
    }

    div.modal .modal-header .modal-title {
        font-size: 14px;
    }

    .influential_contributors_custom_modal .influential-data-list .sNumber {
        padding-left: 1rem !important;
    }

    .influential_contributors_custom_modal .headerActiveCreator>div:nth-child(1),
    .influential_contributors_custom_modal .users_row>div:nth-child(1) {
        width: 50px !important;
    }

    .influential_contributors_custom_modal .headerActiveCreator>div:nth-child(2),
    .influential_contributors_custom_modal .users_row>div:nth-child(2) {
        width: calc(60% - 50px) !important;
    }

    .influential_contributors_custom_modal .headerActiveCreator>div:nth-child(3),
    .influential_contributors_custom_modal .users_row>div:nth-child(3) {
        width: 20% !important;
    }

    div.before-search+div,
    .influential_contributors_custom_modal .users_row>div:nth-child(4) {
        width: 25% !important;
    }

    .influential_contributors_custom_modal .headerActiveCreator>div.w-100:nth-child(1) {
        width: 100% !important;
    }

    .influential_contributors_custom_modal .headerActiveCreator>div.w-100+div {
        display: none !important;
    }

    .influential_contributors_custom_modal .headerActiveCreator>div.w-100>.user_details_inner {
        width: calc(100% - 150px);
    }

    .influential_contributors_custom_modal .headerActiveCreator>div.w-100>.user_details_inner+div {
        width: 150px;
    }
}

@media(max-width:574px) {
    .recentPostSliderWrap li.splide__slide>.item-post {
        padding: 0.75rem !important;
    }

    .recentPostSliderWrap li.splide__slide {
        width: 400px !important;
    }

    .influential_contributors_custom_modal .headerActiveCreator>div>span {
        font-size: 12px;
    }

    .user_details_section.profile_with_users_list .col-6.max-img,
    .user_details_section.profile_with_users_list .col-6.max-img+div {
        width: 100%;
    }

    .user_details_section.profile_with_users_list .col-6.max-img+div {
        margin-top: 15px;
    }

    .custom-top-performing .modal-header strong,
    .custom-top-performing .cs-detail p:first-child {
        font-size: 14px;
    }

    .user_details_section.profile_with_users_list .col-6.max-img img {
        min-height: 285px;
        max-height: 285px;
    }

    .success-manager-info .tb_cp_image {
        max-width: 110px;
    }
}

@media(max-width:479px) {
    .container-fluid.custom-gap {
        padding-left: 12.5px;
        padding-right: 12.5px;
    }

    .customer-support-main {
        padding: 15px 12.5px;
    }

    .customer-info {
        flex-wrap: wrap;
    }

    .customer-info .cust-info-mob+div {
        margin-top: 0px !important;
    }

    .customer-support-main>div>.col-xl-6+div {
        margin-top: 15px;
    }

    .dashboard-mac .card.card-custom .cutsom-tab-viewall {
        border: 0;
        flex-direction: column;
    }

    .dashboard-mac .card.card-custom .cutsom-tab-viewall>li {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .performing-widget th:last-child {
        padding-left: 15px;
    }

    .dashboard-mac .card.card-custom .cutsom-tab-viewall>li>button {
        margin-left: 0;
    }

    .influential_contributors_custom_modal .headerActiveCreator>div:nth-child(1),
    .influential_contributors_custom_modal .users_row>div:nth-child(1) {
        width: 35px !important;
    }

    .influential_contributors_custom_modal .headerActiveCreator>div:nth-child(2),
    .influential_contributors_custom_modal .users_row>div:nth-child(2) {
        width: calc(60% - 35px) !important;
    }

    .influential_contributors_custom_modal .before-search {
        padding-left: 10px;
        line-height: normal;
    }

    .influential_contributors_custom_modal .influential-data-list .users_row {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    .influential_contributors_custom_modal .headerActiveCreator>div:nth-child(2),
    .influential_contributors_custom_modal .users_row>div:nth-child(2) {
        position: relative;
        flex-direction: column;
        width: calc(50% - 40px) !important
    }

    .influential_contributors_custom_modal .symbol {
        justify-content: flex-start;
    }

    .influential_contributors_custom_modal .before-search,
    .influential_contributors_custom_modal .users_row>div:nth-child(3) {
        padding-left: 5px;
    }

    .influential_contributors_custom_modal .conn_user_info .my-auto {
        position: absolute;
        right: 0;
        top: 0;
        margin-right: 0 !important;
    }

    .influential_contributors_custom_modal .headerActiveCreator>div>span {
        font-size: 11px;
    }

    .influential_contributors_custom_modal .author_name {
        margin-right: 0;
    }

    div.before-search+div,
    .influential_contributors_custom_modal .users_row>div:nth-child(4) {
        padding-right: 10px !important;
    }

    .influential_contributors_custom_modal .con_user_img span {
        height: 32px;
        width: 32px;
        margin-bottom: 3px;
    }

    .success-manager-info .tb_cp_image {
        max-width: 90px;
    }
}

@media(max-width:449px) {
    .recentPostSliderWrap li.splide__slide {
        width: 90% !important;
    }

    .row .item-post-left {
        width: 144px;
        height: 204px;
    }

    .row .item-post-left,
    .row .item-post-right {
        width: 100%;
    }

    .recentPostSliderWrap li.splide__slide>.item-post {
        padding: 1rem !important;
    }

    .row .item-post-left .home_recentPost img {
        width: 100%;
    }

    .row .item-post-right {
        padding-left: 1rem;
        padding-right: 1rem;
        margin-top: 15px;
    }

    .item-post .item-content {
        min-height: 54px;
    }

    .recent-post-section>.per-head {
        flex-direction: column;
    }

    .recent-post-section>.per-head>* {
        width: 100%;
    }

    .recent-post-section>.per-head>.recentActions {
        margin-top: 10px;
        justify-content: flex-start !important;
    }

    .recent-post-section>.per-head>.recentActions>span:first-child {
        margin-left: 0 !important;
    }
}

@media(max-width:374px) {
    .success-manager-info .tb_cp_image {
        max-width: 80px;
    }
}